import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

export default function App() {
  return (
    <div>
      <h4 style={{ textAlign: "center", fontSize: "25px"}}></h4>
      <div style={{ marginBottom: "100px" }}>
        <Card
          style={{
            width: 800,
            backgroundColor: "white",
            margin: "auto",
            display: "flex",
            flexDirection: "row"

          }}
        >

          <CardContent>
            {/* <Icon source={WholesaleMajor} tone="base" style={{width : "2rem", margin: "auto"}}/> */}
            <Typography style={{ fontSize: 22, fontWeight: "bold", marginBottom: "10px", marginLeft: "30px", marginTop: "30px" }} color="black" gutterBottom>
            Customizing Shopify checkout 🎉
              
            </Typography>
            <Typography style={{ fontSize: 15, marginBottom: "10px", marginLeft: "30px" , marginRight: "30px"}}>
            Merchants use <a href="https://help.shopify.com/en/manual/checkout-settings/tips" target="_blank">Shopify checkout</a> to accept orders and receive payments wherever they sell online. You can augment Shopify checkout with new functionality by building an app.
            </Typography>
            <Typography style={{ fontSize: 15, marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
            After a customer adds products to a cart, they use Shopify checkout to enter their customer, shipping, and payment information before placing the order.
            </Typography>

            <Typography style={{ fontSize: 15, marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
            Developers can create apps that extend Shopify checkout to include functionality that isn't provided natively. For example, you can create an app that offers a customer free shipping or other discounts depending on what's in their cart.
            </Typography>

            <Typography style={{ fontSize: 15, marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
            Merchants install these apps on their store in the Shopify admin. In the Shopify admin, merchants can use the <a href="https://shopify.dev/docs/apps/checkout/best-practices/testing-ui-extensions#test-the-extension-in-the-checkout-editor" target="_blank">checkout editor</a> to place a <a href="https://shopify.dev/docs/api/checkout-ui-extensions" target="_blank">checkout UI extension</a> in the checkout experience
            </Typography>

            <Typography style={{ fontSize: 22, marginBottom: "10px", marginLeft: "30px", marginTop: "40px", fontWeight: "600"}}>Customization options</Typography>

            <Typography style={{ fontSize: 15, marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
            {/* You can customize Shopify checkout using multiple technologies. All customization options are easy to install and upgrade-safe, which enables merchants to continue getting platform benefits when Shopify releases new products such as <a href="https://shopify.dev/docs/apps/checkout/best-practices/testing-ui-extensions#test-with-shop-pay" style={{color: "551A8B"}}>Shop Pay</a>, or features such as <a href="https://shopify.dev/docs/apps/checkout/best-practices/testing-ui-extensions#one-page-checkout" style={{color: "551A8B"}}>One-page checkout.</a> */}
            You can customize Shopify checkout using multiple technologies. All customization options are easy to install and upgrade-safe, which enables merchants to continue getting platform benefits when Shopify releases new products such as Shop Pay, or features such as One-page checkout.
            
            </Typography>

            <Typography style={{ fontSize: 15, marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
            For a detailed breakdown of the available technologies for customizing checkout, and ways that you can extend checkout, refer to <a href="https://shopify.dev/docs/apps/checkout/build-options" target="_blank">Options for customizing Shopify checkout.</a>
            </Typography>
          </CardContent>
          
        </Card>
      </div>
    </div>
  );
}